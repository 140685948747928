<template>
    <div id="sc-view-customer">
        <b-button class="sc-button-back" @click="$router.back()"> <b-icon icon="arrow-left-circle-fill" aria-hidden="true"></b-icon> Back </b-button>

        <div id="sc-header">
            <h3>View Customer</h3>
            <h4>{{ customer.company }}</h4>
        </div>
        <hr />

        <div id="sc-customer-container">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Info" active>
                        <div>
                            <span class="first">Company :</span>
                            <span>{{ customer.company }}</span>
                        </div>
                        <div>
                            <span>Name :</span>
                            <span>{{ customer.name }}</span>
                        </div>
                        <div>
                            <span>Email :</span>
                            <span>{{ customer.email }}</span>
                        </div>
                        <div>
                            <span>Phone :</span>
                            <span>{{ customer.phone }}</span>
                        </div>
                        <div>
                            <span>Truck :</span>
                            <span>{{ customer.truck }}</span>
                        </div>
                        <div>
                            <span>Address :</span>
                            <span>{{ customer.address }}</span>
                        </div>
                        <div>
                            <span>Balance :</span>
                            <span>{{ customer.balance }}</span>
                        </div>
                        <div>
                            <span>Prepay :</span>
                            <span>{{ customer.carryOver }}</span>
                        </div>
                        <div>
                            <span>Products Id :</span>
                            <span>{{ customer.productsId }}</span>
                        </div>
                        <div>
                            <span>Quickbooks Id :</span>
                            <span>{{ customer.quickbooksId }}</span>
                        </div>
                        <div>
                            <span>Notes :</span>
                            <span>{{ customer.notes }}</span>
                        </div>
                    </b-tab>
                    <b-tab title="Tickets">
                        <div id="sc-tickets-container">
                            <h2>Tickets</h2>
                            <v-client-table :data="ticketsList" :columns="columns" :options="options">
                                <!-- <div class="sc-actions-container" slot="actions" slot-scope="{ row }"> -->
                                <!-- <b-form-checkbox @change="onChangeCheckbox($event, row)"></b-form-checkbox> -->
                                <!-- <b-icon-pencil-square class="sc-icon-edit" @click="onClickCreateInvoice(row.id)"></b-icon-pencil-square> -->
                                <!-- </div> -->
                            </v-client-table>
                        </div>
                    </b-tab>
                    <b-tab title="QR Code">
                        <div id="sc-tickets-container-qr">
                            <div id="sc-tickets-header">
                                <h2>QR Codes</h2>
                                <b-button class="sc-button-primary" @click="onClickShowPrintQrcodeAll()">Print All</b-button>
                            </div>
                            <table id="sc-qrcode-table">
                                <tr>
                                    <th>Truck</th>
                                    <th>QRCode</th>
                                    <th>Action</th>
                                </tr>
                                <tr class="sc-qrcode-container" v-for="qrcode in qrcodesList" :key="qrcode.id">
                                    <td>{{ qrcode.cT }}</td>
                                    <td class="sc-qrcode-placeholder"></td>
                                    <td><b-button class="sc-button-primary" @click="onClickShowPrintQrcode(qrcode)">Print</b-button></td>
                                </tr>
                            </table>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>

        <!-- Modal - Print Qrcode -->
        <b-modal id="sc-modal-print-qrcode" ref="sc-modal-print-qrcode" title="Print QRCode" centered hide-footer>
            <vue-html2pdf ref="html2Pdf" pdf-format="a4" pdf-orientation="portrait" pdf-content-width="500px" :show-layout="true" :float-layout="false" :useCORS="true" :letterRendering="true" :enable-download="true" :preview-modal="false" :paginate-elements-by-height="1400" :pdf-quality="2" :manual-pagination="false" :html-to-pdf-options="htmlToPdfOptions">
                <section style="height: 420px; display:flex; flex-direction: row; align-items:center; justify-content:center" slot="pdf-content">
                    <div id="sc-print-qrcode-placeholder"></div>
                    <div class="sc-info" style="display: flex; flex-direction: column; align-items: start; padding: 10px;">
                        <span style="font-size: 12px; width: 120px; text-align: left;"
                            ><b>{{ printQrcodeName }}</b></span
                        >
                        <span style="margin-top: 15px;"
                            ><b># {{ printQrcodeTruck }}</b></span
                        >
                    </div>
                </section>
            </vue-html2pdf>
            <b-button class="mt-3 sc-button-primary" block @click="onClickPrintQRCode"><strong>Print</strong></b-button>
        </b-modal>

        <!-- Modal - Print Qrcode All -->
        <b-modal id="sc-modal-print-qrcode-all" ref="sc-modal-print-qrcode-all" title="Print QRCode" centered hide-footer>
            <vue-html2pdf ref="html2PdfAll" pdf-format="a4" pdf-orientation="portrait" pdf-content-width="500px" :show-layout="true" :float-layout="false" :useCORS="true" :letterRendering="true" :enable-download="true" :preview-modal="false" :paginate-elements-by-height="1400" :pdf-quality="2" :manual-pagination="false" :html-to-pdf-options="htmlToPdfOptions">
                <section v-for="qrcode in qrcodesList" :key="qrcode.cT" style="height: 280px; display:flex; flex-direction: row; align-items:center; justify-content:center;" slot="pdf-content">
                    <div :id="`sc-print-qrcode-placeholder-` + qrcode.cT"></div>
                    <div class="sc-info" style="display: flex; flex-direction: column; align-items: start; padding: 10px;">
                        <span style="font-size: 12px; width: 120px; text-align: left;"><b>{{ qrcode.cN }}</b></span>
                        <span style="margin-top: 15px;"><b># {{ qrcode.cT }}</b></span>
                    </div>
                </section>
            </vue-html2pdf>
            <b-button class="mt-3 sc-button-primary" block @click="onClickPrintQRCodeAll"><strong>Print All</strong></b-button>
        </b-modal>
    </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import APICalls from "./APICalls";
import TicketsAPICalls from "./../ticket/APICalls";
import qrcodeGenerator from "qrcode-generator";
import VueHtml2pdf from "vue-html2pdf";

export default {
    name: "ViewCustomer",
    components: {
        VueHtml2pdf,
    },
    data() {
        return {
            createQR: {},
            customer: {},
            columns: ["id", "customer.company", "products", "linteItemsSku", "truck", "dateTime", "linteItemsQuantity", "quickbooksInvoice"],
            // customer: { id: 1, company: "Test 123", name: "John Company 123", email: "ank@gmail.com", phone: "2043443434", truck: "1,2,3" },
            printQrcode: {},
            printQrcodeName: "",
            printQrcodeTruck: "",
            qrcodesList: [],
            ticketsList: [],
            options: {
                perPage: 200,
                headings: {
                    id: "#",
                    "customer.company": "Customer",
                    linteItemsSku: "SKU",
                    linteItemsQuantity: "Quantity",
                    dateTime: "Date",
                },
                templates: {
                    linteItemsSku(h, ticket) {
                        let res = "";
                        _.each(ticket.lineitems, (lineitem) => {
                            res += lineitem.item.sku + "\n";
                        });
                        return res;
                    },
                    linteItemsQuantity(h, ticket) {
                        let res = "";
                        _.each(ticket.lineitems, (lineitem) => {
                            res += lineitem.quantity + "\n";
                        });
                        return res;
                    },
                    dateTime(h, ticket) {
                        return moment(ticket.createdAt)
                            .subtract("05:00:00")
                            .format("lll");
                    },
                },
            },
            htmlToPdfOptions: {
                // filename: this.customer.company,
                html2canvas: {
                    scrollX: 0,
                    scrollY: 0,
                },
            }
        };
    },
    methods: {
        onClickPrintQRCode() {
            this.$refs.html2Pdf.generatePdf();
        },
         onClickPrintQRCodeAll() {
            this.$refs.html2PdfAll.generatePdf();
        },
        onClickShowPrintQrcode(qrcode) {
            this.printQrcodeName = "";
            this.printQrcodeTruck = "";
            this.$refs["sc-modal-print-qrcode"].show();

            setTimeout(() => {
                let contentJson = qrcode;
                this.printQrcodeName = contentJson.cN;
                this.printQrcodeTruck = contentJson.cT;

                // Print
                let typeNumber = 0;
                let errorCorrectionLevel = "L";
                let qr = qrcodeGenerator(typeNumber, errorCorrectionLevel);
                qr.addData(JSON.stringify(qrcode));
                qr.make();
                let qrcodeImg = qr.createImgTag(4);
                document.getElementById("sc-print-qrcode-placeholder").innerHTML = qrcodeImg;
            }, 500);
        },
        onClickShowPrintQrcodeAll() {
            this.$refs["sc-modal-print-qrcode-all"].show();

            _.each(this.qrcodesList, (qrcode) => {
                let typeNumber = 0;
                let errorCorrectionLevel = "L";
                let qr = qrcodeGenerator(typeNumber, errorCorrectionLevel);
                setTimeout(() => {
                    qr.addData(JSON.stringify(qrcode));
                    qr.make();
                    let qrcodeImg = qr.createImgTag(4);
                    document.getElementById("sc-print-qrcode-placeholder-"+qrcode.cT).innerHTML = qrcodeImg;
                }, 500);
            });
        },
        generateQrcodes() {
            this.qrcodesList = [];
            let truckString = this.customer.truck;
            if (!truckString || truckString.trim().length == 0) return;

            let truckList = truckString.split(",");

            // Create QRCode
            let typeNumber = 0;
            let errorCorrectionLevel = "L";
            _.each(truckList, (truck, i) => {
                truck = truck.trim();
                let qr = qrcodeGenerator(typeNumber, errorCorrectionLevel);
                let content = {
                    cId: this.customer.id.toString(),
                    cN: this.customer.name.toString(),
                    cT: truck.toString(),
                };
                this.qrcodesList.push(content);

                // Put in Placeholder
                setTimeout(() => {
                    qr.addData(JSON.stringify(content));
                    qr.make();
                    let qrcodeImg = qr.createImgTag(4);
                    document.getElementsByClassName("sc-qrcode-placeholder")[i].innerHTML = qrcodeImg;
                }, 500);
            });
        },
        async getCustomer() {
            let id = this.$router.currentRoute.params.id;
            let customer = await APICalls.getCustomer(id);
            this.customer = customer;
            this.htmlToPdfOptions.filename = customer.company + ".pdf";
        },
        async getTickets() {
            let tickets = await TicketsAPICalls.getTickets();
            this.ticketsList = [];
            _.each(tickets, (ticket) => {
                if (ticket.customer.id === this.customer.id) {
                    this.ticketsList.push(ticket);
                }
            });
        },
    },
    watch: {
        ticketsList: function(val) {
            _.each(val, (v) => {
                let products = "";
                _.each(v.lineitems, (obj) => {
                    products += obj.item.name + "\n";
                });

                v.products = products;
            });
        },
    },
    mounted() {
        this.getCustomer()
            .then(() => {
                this.getTickets();
                this.generateQrcodes();
            })
            .catch((err) => {
                console.log(err);
            });
    },
};
</script>

<style lang="scss">
#sc-view-customer {
    #sc-header {
        background: transparent;
        box-shadow: none;
        display: flex;
    }

    #sc-customer-container {
        margin: 20px 0px;

        div {
            margin-bottom: 10px;

            span:nth-child(1) {
                display: inline-block;
                width: 200px;
                font-size: 20px;
                font-weight: bold;
            }

            span:nth-child(2) {
                font-size: 20px;
            }
        }

        #sc-tickets-container-qr {
            #sc-tickets-header {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                button {
                    margin: 0;
                }
            }
        }
    }
    #sc-qrcode-table {
        width: 100%;

        th {
            padding: 10px;
            text-align: center;
        }

        .sc-qrcode-container {
            text-align: center;

            .sc-qrcode-placeholder {
                img {
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }

    .sc-button-primary {
        width: 150px;
        margin-top: 20px;
        font-weight: bold;
    }
}

#sc-modal-print-qrcode {
    .modal-content {
        // width: 563px;
        // height: 720px;

        .modal-body {
            .sc-info {
                text-align: center;
                margin: 10px;
            }

            #sc-print-qrcode-placeholder {
                display: flex;
                align-items: center;
                justify-content: center;
                // width: 150px;
                // height: 150px;
            }
        }
    }
}
</style>
